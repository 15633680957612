var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accentColor":"primary"}},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"NIP - Nama"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("NIP - Nama")]),_c('v-select',{class:[
                      { 'is-valid': !errors[0] },
                      { 'is-invalid': errors[0] } ],attrs:{"options":_vm.optionsPgw,"label":"nama","reduce":function (x) { return x.nip; },"filterable":false},on:{"search":_vm.onSearch},model:{value:(_vm.form.nip),callback:function ($$v) {_vm.$set(_vm.form, "nip", $$v)},expression:"form.nip"}}),(errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v("Tanggal")]),_c('datepicker',{attrs:{"placeholder":"Tanggal","input-class":"datePicker is-valid","bootstrap-styling":true},model:{value:(_vm.form.tgl),callback:function ($$v) {_vm.$set(_vm.form, "tgl", $$v)},expression:"form.tgl"}})],1)]),_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Kelas Jabatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('CInput',{class:[
                    { 'is-valid': !errors[0] },
                    { 'is-invalid': errors[0] } ],attrs:{"label":"Kelas Jabatan","type":"number","placeholder":"Kelas Jabatan"},model:{value:(_vm.form.kelas_jabatan),callback:function ($$v) {_vm.$set(_vm.form, "kelas_jabatan", $$v)},expression:"form.kelas_jabatan"}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tempat","placeholder":"Tempat","isValid":true},model:{value:(_vm.form.tempat),callback:function ($$v) {_vm.$set(_vm.form, "tempat", $$v)},expression:"form.tempat"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Keterangan","rows":"9","isValid":true},model:{value:(_vm.form.keterangan),callback:function ($$v) {_vm.$set(_vm.form, "keterangan", $$v)},expression:"form.keterangan"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[(!_vm.form.upload_dokumen)?_c('CInputFile',{attrs:{"label":"Sertifikat","placeholder":"Pilih Sertifikat","isValid":true,"description":"Max file 2MB dan hanya menerima PDF/Image","custom":""},on:{"change":_vm.uploadFile}}):_vm._e(),(_vm.loadingFile)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_vm._e(),(_vm.form.upload_dokumen)?_c('CButtonGroup',{attrs:{"size":"sm"}},[_c('CButton',{staticClass:"px-4",attrs:{"color":"success"},on:{"click":_vm.downloadFile}},[_vm._v("Download File Uji Kompetensi")]),_c('CButton',{staticClass:"px-4",attrs:{"color":"danger"},on:{"click":_vm.deleteFile}},[_vm._v("Hapus File")])],1):_vm._e(),_c('hr')],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }